<template>
  <v-card>
    <v-toolbar dense flat class="teal" dark>
      <v-toolbar-title>Editar critério</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="criteria.name"
              :counter="100"
              label="Nome do critério"
              placeholder="...fibra rompida"
              required
              outline
            ></v-text-field>
            <v-text-field
              v-model="criteria.weight"
              label="Peso do critério"
              type="number"
              placeholder="100"
              required
              outline
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn color="success" outlined @click="save()"> Salvar </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="closeDialog()"> Cancelar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EditCriteria",
  props: ["status"],

  data() {
    return {
      criteria: Object.assign({}, this.status)
    };
  },
  created() {},
  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async save() {
      try {
        const response = await this.$http.put("/criteria", this.criteria);
        if (response) this.$toast.success("Critério editada com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
      }
    }
  }
};
</script>

<style></style>
