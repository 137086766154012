<template>
  <v-container>
    <v-card flat>
      <v-card-text>
        <v-data-table
          v-if="criterias"
          :headers="headers"
          :items="criterias"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar dense flat class="teal" dark>
              <v-toolbar-title>Critérios</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{}">
                  <v-btn
                    class="mt-2 mb-2"
                    icon
                    x-large
                    to="/criterios/adicionar"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Adicionar novo critério
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="edit(item)" class="mr-2 colorLink"
              >mdi-square-edit-outline</v-icon
            >
            <v-icon @click="remove(item)" class="mr-2" color="error"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" max-width="500px" v-if="editDialog">
      <editCriteria
        :status="criteria"
        v-on:update="loadCriterias()"
        v-on:close-dialog="editDialog = !editDialog"
      />
    </v-dialog>
    <v-dialog v-model="removeDialog" max-width="500px" v-if="removeDialog">
      <removeCriteria
        :status="criteria"
        v-on:update="loadCriterias()"
        v-on:close-dialog="removeDialog = !removeDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import editCriteria from "./edit";
import removeCriteria from "./remove";
export default {
  components: {
    editCriteria,
    removeCriteria
  },
  data() {
    return {
      criterias: [],
      criteria: {},
      editDialog: false,
      removeDialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Critério", value: "name" },
        { text: "Peso", value: "weight" },
        { text: "Ações", value: "actions" }
      ]
    };
  },
  created() {
    this.loadCriterias();
  },
  methods: {
    async loadCriterias() {
      const response = await this.$http.get("/criteria");
      this.criterias = response.data;
    },
    async edit(criteria) {
      this.editDialog = true;
      this.criteria = criteria;
    },
    async remove(criteria) {
      this.removeDialog = true;
      this.criteria = criteria;
    }
  }
};
</script>

<style></style>
