<template>
  <v-card flat>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Remover Critério</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title> Você deseja remover este critério?</v-card-title>
    <v-card-text>
      <v-alert dense outlined type="error" class="mt-2">
        Atenção! Ao confirmar a alteração,
        <strong> o critério será removido</strong>!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="closeDialog()">
        <v-icon arge>mdi-close</v-icon> Cancelar</v-btn
      >
      <v-btn color="success" text @click="deleteCriteria()">
        <v-icon arge>mdi-check</v-icon> Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RomoveCriteria",
  props: ["status"],

  data() {
    return {
      criteria: Object.assign({}, this.status)
    };
  },
  created() {},
  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async deleteCriteria() {
      try {
        const response = await this.$http.delete(
          `/criteria/${this.criteria.id}`
        );
        if (response) this.$toast.success("Critério excluido com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
        this.$emit("close-dialog");
      }
    }
  }
};
</script>

<style></style>
